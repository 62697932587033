* {
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  min-width: 800px;
  -webkit-font-smoothing: antialiased;
  color: #333;
  background-color: #faf2ee;
  font-family: Microsoft YaHei;
  font-size: 15px;
  line-height: 1.75;
  overflow-x: hidden;
}

html, body {
  scroll-behavior: smooth;
}

:root {
  --skin-color1: #5bb3df;
  --skin-color2: #9c9c9c;
  --skin-color-hover: #71cbf8;
  --skin-color-back1-1: #5ba6c9;
  --skin-color-back1-2: #66ccffa9;
  --skin-color-footer: #346d8a;
}

a {
  color: inherit;
  text-decoration: none;
}

a:link:hover {
  opacity: .895;
  text-decoration: underline;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.ch {
  text-align: center;
  padding: 60px 0;
}

.block {
  margin-bottom: 40px;
}

.chtitle {
  background-image: linear-gradient(to left, var(--skin-color-back1-1), var(--skin-color-back1-2) );
  background-image: -webkit-linear-gradient(left, var(--skin-color-back1-1), var(--skin-color-back1-2) );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  font-size: 30px;
  display: inline-block;
}

a.button {
  background: linear-gradient(to top, var(--skin-color-back1-1), var(--skin-color-back1-2) );
  color: #fff;
  text-shadow: none;
  border-radius: 99px;
  margin-right: 10px;
  padding: 6px 20px;
  text-decoration: none;
  display: inline-block;
}

a.button:hover {
  text-decoration: none;
}

::selection {
  background-color: #0000001a;
}

.article {
  color: var(--skin-color2);
}

.hover-in {
  transition: all .3s ease-out;
}

.hover-out {
  transition: all .3s ease-in;
}

.header {
  z-index: 90;
  width: 100%;
  background-color: #fff;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 5px 10px #00000005;
}

.header.transparent {
  color: #fff;
  background-color: #0000;
}

.header.transparent .right a {
  color: #fff;
}

.header.transparent .site-logo.black {
  display: none !important;
}

.header.transparent .site-logo.white {
  display: block !important;
}

.header .container {
  height: 100px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header .right a {
  color: initial;
  border-top: 3px solid #0000;
  border-bottom: 3px solid #0000;
  padding: 0 20px;
  line-height: 50px;
  text-decoration: none;
  display: inline-block;
}

.header .left {
  color: #bf2d26;
  font-weight: bold;
}

.header .left .site-logo {
  height: 50px;
}

.header .left .site-logo.black {
  display: block;
}

.header .left .site-logo.white {
  display: none;
}

.header .right a:hover {
  border-bottom: 3px solid var(--skin-color-hover);
  color: var(--skin-color-hover);
}

.header .right a.active {
  border-bottom: 3px solid var(--skin-color1);
  color: var(--skin-color1);
}

.header .mobile-right {
  height: 100%;
  width: 60px;
  display: none;
  position: absolute;
  top: 0;
  right: 5%;
}

.header .mobile-header-button {
  height: 40px;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 0 10px;
  display: flex;
}

.header .mobile-header-button svg {
  width: 26px;
  height: 26px;
}

.app {
  z-index: 3;
  background-color: #faf2ee;
  position: relative;
}

.splash-screen {
  z-index: 0;
  height: 100%;
  width: 100%;
  background: #212121 center / cover;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.splash-screen .background {
  height: 100%;
  width: 100%;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.splash-screen .background:after {
  content: "";
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: #00000080;
  position: absolute;
}

.splash-screen .content {
  z-index: 2;
  color: #fff;
  text-align: center;
  font-size: 40px;
  position: relative;
}

.splash-screen .content .intro {
  margin-top: 50px;
}

.splash-screen .content .intro a {
  background-color: #0000004d;
  border-radius: 99px;
  padding: 16px 50px;
  font-size: 18px;
  font-weight: normal;
}

.splash-screen .content .intro a:hover {
  text-decoration: none;
}

.page-index {
  margin-top: 130vh;
  padding-top: 60px;
  position: relative;
}

.page-index:before {
  content: "";
  height: 30vh;
  width: 100%;
  background-image: linear-gradient(to top, #faf2ee, #faf2ee00);
  display: block;
  position: absolute;
  top: -30vh;
  left: 0;
}

.splash-swiper {
  height: 100%;
  width: 100%;
}

.splash-swiper .swiper-wrapper .swiper-slide {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
}

.splash-swiper .swiper-wrapper .swiper-slide .cover {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #0000004d;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.page-main {
  content: "";
  z-index: 5;
  background-color: #faf2ee;
  margin-top: 100px;
  position: relative;
}

.page-index .swiper-box {
  height: 500px;
  color: #ffffff1a;
  background: #222;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 40px;
  font-size: 50px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 5px 10px #00000014;
}

.page-index .swiper-box .swiper-container {
  height: 100%;
  width: 100%;
}

.page-index .swiper-box .swiper-container .swiper-wrapper .swiper-slide {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.page-index .about h3 {
  background-image: -webkit-linear-gradient(left, #d8b55d, #917c4c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  font-size: 30px;
  display: inline-block;
}

.page-index .about {
  color: var(--skin-color2);
}

.page-index .about .item {
  padding: 40px 0;
}

.page-index .about .reversal {
  text-align: right;
}

.page-index .enter-block {
  text-align: center;
  color: #fff;
  display: flex;
}

.page-index .enter-block .item {
  background: url("projects.ea8f22f1.jpg") 50% 35% / cover no-repeat;
  position: relative;
  overflow: hidden;
}

.page-index .enter-block .item:after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #0006;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.page-index .enter-block .item a {
  height: 100%;
  width: 100%;
  display: block;
}

.page-index .enter-block .item a:hover {
  text-decoration: none;
}

.page-index .enter-block .item h3 {
  text-align: right;
  z-index: 10;
  opacity: .7;
  font-size: 70px;
  transition: all .3s;
  position: absolute;
  bottom: -30px;
  right: -10px;
}

.page-index .enter-block .item:hover h3 {
  opacity: 1;
  color: #000c;
  bottom: 0;
  right: 5%;
}

.page-index .enter-block .item:hover:after {
  background-color: #0000;
}

.page-index .enter-block .item-project-box {
  width: 46%;
  height: 400px;
  margin-right: 4%;
}

.page-index .enter-block .item-project {
  height: 100%;
  width: 100%;
  background: url("projects.ea8f22f1.jpg") center / cover no-repeat;
  border-radius: 10px;
}

.page-index .enter-block .right {
  width: 50%;
}

.page-index .enter-block .right .item {
  height: 120px;
  background-color: #212121;
  border-radius: 10px;
  margin-bottom: 20px;
}

.page-project .fixed-title {
  z-index: 999;
  text-align: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #ffffffc9;
  font-size: 22px;
  display: none;
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
}

.page-project .fixed-title h2 {
  margin: 0;
  font-weight: normal;
}

.page-project .fixed-title span {
  margin-left: 20px;
  font-size: 13px;
  font-weight: normal;
  display: inline-block;
}

@media (max-width: 800px) {
  .page-project .fixed-title {
    top: 60px;
  }
}

.page-project .project-nav {
  text-align: center;
}

.page-project .project-nav a {
  background: linear-gradient(to top, #00000014, #0000001a);
  border-radius: 6px;
  margin: 0 10px;
  padding: 5px 15px;
  transition: all .3s;
  display: inline-block;
}

.page-project .project-nav a:hover {
  background: linear-gradient(to top, var(--skin-color-back1-1), var(--skin-color-back1-2) );
  color: #fff;
  text-decoration: none;
}

.page-project .item-title {
  text-align: center;
  margin: 40px 0;
  font-size: 22px;
}

.page-project .item-title h2 {
  margin: 0;
  font-weight: normal;
}

.page-project .item-title span {
  margin-left: 20px;
  font-size: 13px;
  font-weight: normal;
  display: inline-block;
}

.product-block-list {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.product-block-list .product-block-wrapper {
  width: 49%;
  height: 280px;
  margin: 10px 0;
}

.products .product-block {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}

.products .product-block .item-img {
  height: 100%;
  width: 100%;
  background-color: #efedce;
  background-image: url("https://thonly.name/image/intro-post.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 10px #0000001f;
}

.products .product-block .item-img:after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #00000080;
  border-radius: 10px;
  position: absolute;
}

.products .product-block .item-content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #fff;
  text-shadow: 0 3px 10px #0009;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  transform: translateZ(40px);
}

.products .product-block .item-content .item-content-inner {
  text-align: left;
  display: flex;
}

.products .product-block .item-content .logo-area {
  width: 40%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.products .product-block .item-content .content {
  width: 60%;
}

.products .product-block .item-content img {
  width: 85%;
}

.products .product-block .item-content img.s-logo {
  width: 70%;
}

.products .product-block .item-content h3 {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: normal;
}

.products .product-block .item-content p {
  padding: 10px 0 20px;
  font-size: 14px;
}

.footer {
  background-color: var(--skin-color-footer);
  color: #fff;
  margin-top: 100px;
  padding: 40px 0 80px;
}

.footer .container {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.footer .left .site-logo {
  height: 80px;
}

.footer .right {
  opacity: .6;
  text-align: right;
  font-size: 12px;
}

.footer .right a {
  display: block;
}

.joinus .list {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.joinus .item {
  box-sizing: border-box;
  width: 31%;
  background-color: #fff;
  border-radius: 6px;
  padding: 15px 20px;
  font-size: 14px;
  display: inline-block;
  box-shadow: 0 3px 10px #0000000a;
}

.joinus .item h3 {
  font-size: 18px;
}

.joinus .item p {
  color: #888;
}

.joinus .action {
  margin: 20px 0;
}

.page-header {
  height: 280px;
  position: relative;
}

.page-header .container {
  text-align: center;
  position: relative;
}

.page-header .header-background {
  width: 100%;
  height: 300px;
  filter: blur(10px);
  z-index: -1;
  background-color: #faf2ee;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  transform: scale(1.2);
}

.page-header .header-background:after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#faf2ee00 0%, #faf2ee 100%);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.page-header h1 {
  z-index: 5;
  font-size: 56px;
  line-height: 280px;
  position: relative;
}

.page-header .bg-title {
  z-index: 1;
  width: 100%;
  opacity: .1;
  pointer-events: none;
  font-size: 128px;
  font-weight: bolder;
  line-height: 240px;
  position: absolute;
  top: 0;
  left: 0;
}

.page-contact {
  text-align: center;
}

.page-contact .block {
  margin: 80px 0 150px;
}

.page-contact h3 {
  background-color: var(--skin-color2);
  background-image: linear-gradient(to top, var(--skin-color-back1-1), var(--skin-color-back1-2) );
  color: #fff;
  height: 60px;
  width: 50%;
  border-radius: 30px;
  margin: 0 auto 60px;
  font-size: 20px;
  font-weight: normal;
  line-height: 60px;
}

.timeline-area .article {
  width: 70%;
  margin: 30px auto 60px;
}

.timeline-area .timeline-box {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.timeline-area .timeline-box:after {
  content: "";
  width: 3px;
  height: 100%;
  background-color: #00000004;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.timeline-area .item {
  text-align: right;
  box-sizing: border-box;
  min-height: 100px;
  justify-content: flex-start;
  align-items: center;
  margin: 30px 0;
  display: flex;
}

.timeline-area .item .content {
  box-sizing: border-box;
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
  flex-grow: 0;
  flex-shrink: 1;
  margin: 0 40px;
  padding: 20px;
  display: inline-block;
  position: relative;
  box-shadow: 0 6px 12px #00000014;
}

.timeline-area .item .content h3 {
  color: var(--skin-color2);
  font-weight: normal;
}

.timeline-area .item .image-box {
  box-sizing: border-box;
  text-align: left;
  width: 50%;
  margin: 0 40px;
  display: block;
}

.timeline-area .item .image-box img {
  max-width: 75%;
  max-height: 280px;
  border-radius: 10px;
  box-shadow: 0 6px 12px #00000014;
}

.timeline-area .item:nth-child(2n+1) .content:before {
  content: "";
  width: 20px;
  height: 20px;
  z-index: 10;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  right: -47px;
  transform: translateY(-50%);
  box-shadow: 0 6px 12px #00000014;
}

.timeline-area .item:nth-child(2n+1) .content:after {
  content: "";
  width: 0;
  height: 0;
  border: 10px solid #0000;
  border-left-color: #fff;
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
}

.timeline-area .item:nth-child(2n) {
  text-align: left;
  flex-direction: row-reverse;
}

.timeline-area .item:nth-child(2n) .content:before {
  content: "";
  width: 20px;
  height: 20px;
  z-index: 10;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: -46px;
  transform: translateY(-50%);
  box-shadow: 0 6px 12px #00000014;
}

.timeline-area .item:nth-child(2n) .content:after {
  content: "";
  width: 0;
  height: 0;
  border: 10px solid #0000;
  border-right-color: #fff;
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
}

.timeline-area .item:nth-child(2n) .image-box {
  text-align: right;
}

@media (max-width: 800px) {
  body {
    min-width: 0;
    font-size: 14px;
    overflow-x: hidden;
  }

  .container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 5%;
  }

  .page-main {
    margin-top: 60px;
  }

  .page-header .header-background {
    transform: scale(1);
  }

  .header {
    height: 60px;
    max-height: 60px;
    overflow: hidden;
  }

  .header .container {
    height: auto;
    display: block;
  }

  .header .left {
    height: 60px;
  }

  .header .left .site-logo {
    height: 30px;
    padding: 15px 0;
  }

  .header .right a, .header .mobile-right {
    display: block;
  }

  .header.transparent svg, .header.transparent svg path {
    fill: #fff;
  }

  .header.mobile-active {
    max-height: 1200px;
    height: auto;
    color: #333;
    background-color: #fff;
    padding-bottom: 20px;
  }

  .header.mobile-active .right a {
    color: #333;
  }

  .splash-screen .content {
    font-size: 22px;
  }

  .page-header h1 {
    font-size: 40px;
  }

  .page-header .bg-title {
    font-size: 60px;
  }

  .timeline-area .article, .timeline-area .timeline-box {
    width: auto;
  }

  .timeline-area .timeline-box:after {
    display: none;
  }

  .timeline-area .timeline-box .item {
    text-align: left;
    display: block;
  }

  .timeline-area .timeline-box .item:nth-child(2n) {
    text-align: left;
    flex-direction: row;
  }

  .timeline-area .timeline-box .item .content:before, .timeline-area .timeline-box .item .content:after {
    display: none;
  }

  .timeline-area .timeline-box .item .content {
    width: 100%;
    margin: 0;
  }

  .timeline-area .timeline-box .item .image-box {
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .timeline-area .timeline-box .item .image-box img {
    max-width: 100%;
    margin: 20px 0;
  }

  .page-project .project-nav a {
    margin-bottom: 10px;
  }

  .page-project .item-title h2 {
    font-size: 26px;
  }

  .product-block-list .product-block-wrapper {
    width: 100%;
    height: 220px;
  }

  .products .product-block .item-content {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    display: flex;
  }

  .products .product-block .item-content h3 {
    font-size: 18px;
  }

  .products .product-block .item-content p {
    font-size: 12px;
  }

  .footer .container {
    display: block;
  }

  .footer .container .left, .footer .container .right {
    text-align: center;
  }

  .footer .container .right {
    margin-top: 20px;
    font-size: 12px;
  }

  .footer .container .left .site-logo {
    width: 70%;
    height: auto;
  }

  .page-index .enter-block {
    height: auto;
    display: block;
  }

  .page-index .enter-block .item h3 {
    font-size: 50px;
    bottom: -25px;
    right: -5px;
  }

  .page-index .enter-block .item-project {
    height: 200px;
  }

  .page-index .enter-block .item-project, .page-index .enter-block .right {
    width: 100%;
  }
}

.BackToTop {
  opacity: .5;
  cursor: pointer;
  transform-origin: 100% 100%;
  transition: all .5s;
  position: fixed;
  bottom: 50px;
  right: 15px;
  transform: scale(.7);
}

.BackToTop:hover {
  opacity: 1;
}

/*# sourceMappingURL=index.4debca30.css.map */
